import React, { lazy, Suspense, useContext } from "react";
import { Redirect, BrowserRouter, Switch, Route } from "react-router-dom";
import AuthContext from "contexts/Auth";
import styled from "@emotion/styled";

import Header from "components/layouts/Header";
import SideMenu from "components/layouts/SideMenu";
import Loader from "components/Common/Loader";
import ErrorFallback from "components/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { useRedirect } from "hooks/useRedirect";

const Auth = lazy(() => import("pages/Auth"));
const Login = lazy(() => import("pages/Auth/Login"));
const Verify = lazy(() => import("pages/Auth/Verify"));
const Home = lazy(() => import("pages/Home"));
const MedicalSearch = lazy(() => import("pages/Medical/MedicalSearch"));
const MedicalDetail = lazy(() => import("pages/Medical/MedicalDetail"));
// const MedicalAdd = lazy(() => import("pages/Medical/MedicalAdd"));
const Message = lazy(() => import("pages/Message"));
const Article = lazy(() => import("pages/User/Article"));
const Delivery = lazy(() => import("pages/User/Delivery"));
const NotificationSearch = lazy(() =>
  import("pages/Notification/NotificationSearch")
);
const NotificationDetail = lazy(() =>
  import("pages/Notification/NotificationDetail")
);
const LabNotify = lazy(() => import("pages/Notification/LabNotify"));
const AppNotify = lazy(() => import("pages/Notification/AppNotify"));
const UserSearch = lazy(() => import("pages/User/UserSearch"));
const UserDetail = lazy(() => import("pages/User/UserDetail"));
const ProfileDetail = lazy(() => import("pages/Profile"));
const MemberSearch = lazy(() => import("pages/Member/MemberSearch"));
const MemberDetail = lazy(() => import("pages/Member/MemberDetail"));
const DataSearch = lazy(() => import("pages/DataDownload/DataSearch"));
const Activation = lazy(() => import("pages/Activation"));

const Error404 = lazy(() => import("components/Error404"));

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 80px);
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
`;

const Router = () => {
  const {
    isLoggedIn = false,
    verified = false,
    profile = {},
  } = useContext(AuthContext);
  const { redirect } = useRedirect(profile.role);

  if (redirect) {
    return (
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/404" component={Error404} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Header />
      <ContentContainer>
        {isLoggedIn && verified ? <SideMenu profile={profile} /> : null}
        <Content>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {isLoggedIn && verified ? (
              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/home" component={Home} />
                  <Route exact path="/members" component={MemberSearch} />
                  <Route path="/members/:memberId" component={MemberDetail} />
                  <Route exact path="/medicals" component={MedicalSearch} />
                  <Route
                    path="/medicals/:medicalId"
                    component={MedicalDetail}
                  />
                  <Route exact path="/message" component={Message} />
                  <Route path="/message/:chatId" component={Message} />
                  <Route path="/articles" component={Article} />
                  <Route path="/delivery" component={Delivery} />
                  <Route
                    exact
                    path="/notifications"
                    component={NotificationSearch}
                  />
                  <Route
                    path="/notifications/:notificationId"
                    component={NotificationDetail}
                  />
                  <Route path="/notifications/labs" component={LabNotify} />
                  <Route path="/notifications/app" component={AppNotify} />
                  <Route exact path="/users" component={UserSearch} />
                  <Route path="/users/:userId" component={UserDetail} />
                  <Route path="/profile" component={ProfileDetail} />
                  <Route path="/datadownload" component={DataSearch} />
                  <Route exact path="/activation" component={Activation} />
                  <Route path="/404" component={Error404} />
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            ) : (
              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/verify" component={Verify} />
                  <Route path="*" component={Auth} />
                </Switch>
              </Suspense>
            )}
          </ErrorBoundary>
        </Content>
      </ContentContainer>
    </BrowserRouter>
  );
};

export default Router;
