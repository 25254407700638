import Axios from "axios";

const axios = Axios.create({
  withCredentials: true,
});

if (process.env.NODE_ENV === "development") {
  // 開発環境では baseURL に API URL 設定
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL + "/api/v1";
} else {
  axios.defaults.baseURL = "/api/v1";
}

const get = async (path, params = {}) => {
  try {
    const { data } = await axios.get(path, {
      params,
    });
    if (typeof data !== "object") {
      throw new Error("data is not object");
    }
    return [data, undefined];
  } catch (e) {
    console.log(e);
    return [undefined, e];
  }
};

const post = async (path, params = {}, headers = {}) => {
  try {
    const { data } = await axios.post(path, params, {
      headers,
    });
    return [data, undefined];
  } catch (e) {
    console.log(e);
    return [undefined, e];
  }
};

const put = async (path, params = {}, headers = {}) => {
  try {
    const { data } = await axios.put(path, params, {
      headers,
    });
    return [data, undefined];
  } catch (e) {
    console.log(e);
    return [undefined, e];
  }
};

const destroy = async (path, params = {}) => {
  try {
    const { data } = await axios.delete(path, {
      params,
    });
    return [data, undefined];
  } catch (e) {
    console.log(e);
    return [undefined, e];
  }
};

const fetcher = (url) => axios.get(url).then((res) => res.data);

export { get, post, put, destroy, fetcher };
export default axios;
