import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StContentContainer = styled.div`
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  min-width: ${(props) => props.minWidth};
  min-height: ${(props) => props.minHeight};
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  border-radius: ${(props) => props.borderRadius};
  border-width: ${(props) => props.borderWidth};
  box-shadow: ${(props) => props.boxShadow};
  box-sizing: ${(props) => props.boxSizing};
  float: ${(props) => props.float};
  cursor: ${(props) => props.cursor};
  flex: ${(props) => props.flex};
`;

const ContentContainer = ({
  children,
  display,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  textAlign,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  margin,
  padding,
  backgroundColor,
  border,
  borderBottom,
  borderRadius,
  borderWidth,
  boxShadow,
  boxSizing,
  float,
  cursor,
  onClick,
  flex,
}) => {
  return (
    <StContentContainer
      display={display}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      textAlign={textAlign}
      width={width}
      height={height}
      minWidth={minWidth}
      minHeight={minHeight}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      margin={margin}
      padding={padding}
      backgroundColor={backgroundColor}
      border={border}
      borderBottom={borderBottom}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      boxShadow={boxShadow}
      boxSizing={boxSizing}
      float={float}
      cursor={cursor}
      onClick={onClick}
      flex={flex}
    >
      {children}
    </StContentContainer>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  display: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  textAlign: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  borderBottom: PropTypes.string,
  borderRadius: PropTypes.string,
  borderWidth: PropTypes.string,
  boxShadow: PropTypes.string,
  boxSizing: PropTypes.string,
  float: PropTypes.string,
  cursor: PropTypes.string,
  onClick: PropTypes.func,
  flex: PropTypes.string,
};

export default ContentContainer;
