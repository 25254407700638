import React from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const StContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const rotaion = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StSpinner = styled.div`
  border: 0.3em solid rgba(0, 0, 0, 0.1);
  border-top: 0.3em solid #8fc31e;
  border-radius: 50%;
  width: 2.28571429rem;
  height: 2.28571429rem;
  animation: ${rotaion} 1s linear infinite;
  pointer-events: none;
`;

const Loader = () => {
  return (
    <StContainer>
      <StSpinner />
    </StContainer>
  );
};

export default Loader;
