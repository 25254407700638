// TODO: 認証コード管理追加後に更新する
const rules = {
  admin: {
    static: [
      "home",
      "member",
      "message",
      "users",
      "notifications",
      "medicals",
      "report",
      "activation",
      "members",
      "detail/message",
    ],
  },
  medicalAdmin: {
    static: [
      "home",
      "member",
      "message",
      "users",
      "notifications",
      "report",
      "activation",
      "members",
      "detail/message",
    ],
  },
  medicalOperator: {
    static: [
      "home",
      "member",
      "message",
      "users",
      "notifications",
      "members",
      "detail/message",
    ],
  },
  search: {
    static: ["home", "users", "medicals", "report", "members"],
  },
};

export default rules;
