import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StBtn = styled.button`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  border: ${(props) => props.border};
  border-width: ${(props) => props.borderWidth};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-style: ${(props) => props.fontStyle};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight};
  cursor: pointer;
  outline: none;
  flex: none;

  &:hover {
    border: ${(props) => props.borderHover};
    color: ${(props) => props.colorHover};
    background-color: ${(props) => props.backgroundHover};
  }
  &:active {
    color: ${(props) => props.colorActive};
    background-color: ${(props) => props.backgroundActive};
  }
  &:focus {
    color: ${(props) => props.colorFocus};
    background-color: ${(props) => props.backgroundFocus};
  }
  &:disabled {
    color: ${(props) => props.colorDisabled};
    background-color: ${(props) => props.backgroundDisabled};
    cursor: not-allowed;
  }
`;

const Btn = ({
  width,
  height,
  padding,
  margin,
  border,
  borderWidth,
  borderRadius,
  borderHover,
  backgroundColor,
  color,
  colorHover = "#8FC31F",
  colorActive,
  colorFocus,
  colorDisabled,
  backgroundHover = "#6E8F27",
  backgroundActive,
  backgroundFocus,
  backgroundDisabled = "#EEF0ED",
  fontSize,
  fontWeight,
  fontStyle,
  lineHeight,
  text,
  ref,
  name,
  type,
  value,
  disabled,
  onClick,
}) => {
  return (
    <StBtn
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      border={border}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderHover={borderHover}
      color={color}
      colorHover={colorHover}
      colorActive={colorActive}
      colorFocus={colorFocus}
      colorDisabled={colorDisabled}
      backgroundColor={backgroundColor}
      backgroundHover={backgroundHover}
      backgroundActive={backgroundActive}
      backgroundFocus={backgroundFocus}
      backgroundDisabled={backgroundDisabled}
      fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      ref={ref}
      name={name}
      type={type}
      value={value}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </StBtn>
  );
};

Btn.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  border: PropTypes.string,
  borderWidth: PropTypes.string,
  borderRadius: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundHover: PropTypes.string,
  backgroundActive: PropTypes.string,
  backgroundFocus: PropTypes.string,
  backgroundDisabled: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  colorActive: PropTypes.string,
  colorFocus: PropTypes.string,
  colorDisabled: PropTypes.string,
  fontSize: PropTypes.string,
  fontStyle: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Btn;
