const RoleType = {
  admin: "admin",
  medicalAdmin: "medicalAdmin",
  medicalOperator: "medicalOperator",
  search: "search",
};

const RoleList = {
  管理者: "admin",
  管理者権限: "medicalAdmin",
  一般権限: "medicalOperator",
  サーチ: "search",
};

const RoleListName = {
  admin: "管理者",
  medicalAdmin: "管理者権限",
  medicalOperator: "一般権限",
  search: "サーチ",
};

const RoleSearchListName = [
  { value: "medicalAdmin", label: "管理者権限" },
  { value: "medicalOperator", label: "一般権限" },
];

export { RoleType, RoleList, RoleListName, RoleSearchListName };
