import React, { useMemo } from "react";
import styled from "@emotion/styled";
import SideMenu from "./presenter";
import { ReactComponent as Home } from "assets/icons/home.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { ReactComponent as Message } from "assets/icons/message.svg";
import { ReactComponent as Medical } from "assets/icons/medical.svg";
import { ReactComponent as Notifications } from "assets/icons/notification.svg";
import { ReactComponent as AuthenticationCode } from "assets/icons/authentication_code.svg";
import { ReactComponent as Administrator } from "assets/icons/administrator.svg";

const StHome = styled(Home)`
  fill: #8fc31f;
  stroke: #fff;
`;

const StSearch = styled(Search)`
  stroke: #fff;
  fill: #8fc31f;
`;

const StMessage = styled(Message)`
  stroke: #fff;
  fill: #8fc31f;
`;

const StMedical = styled(Medical)`
  fill: #fff;
`;

const StNotifications = styled(Notifications)`
  stroke: #fff;
  fill: #8fc31f;
`;

const StAuthenticationCode = styled(AuthenticationCode)`
  stroke: #fff;
  fill: none;
`;

const StAdministrator = styled(Administrator)`
  stroke: #fff;
  fill: none;
`;

const SideMenuContainer = ({ profile }) => {
  const SidebarData = useMemo(
    () => [
      {
        title: "ホーム",
        path: "/",
        static: "home",
        icon: <StHome width={"32px"} height={"32px"} />,
      },
      {
        title: "患者検索",
        path: "/members",
        static: "members",
        icon: <StSearch width={"32px"} height={"32px"} />,
      },
      {
        title: "メッセージ",
        path: "/message",
        static: "message",
        icon: <StMessage width={"32px"} height={"32px"} />,
      },
      {
        title: "提携医療機関",
        path: "/medicals",
        static: "medicals",
        icon: <StMedical width={"32px"} height={"32px"} />,
      },
      {
        title: "お知らせ設定",
        path: "/notifications",
        static: "notifications",
        icon: <StNotifications width={"32px"} height={"32px"} />,
      },
      // {
      //   title: "データ出力",
      //   path: "/datadownload",
      //   icon: <StData width={"32px"} height={"32px"} />,
      // },
      {
        title: "認証コード発行",
        path: "/activation",
        static: "activation",
        icon: <StAuthenticationCode width={"32px"} height={"32px"} />,
      },
      {
        title: "ユーザ管理",
        path: "/users",
        static: "users",
        icon: <StAdministrator width={"32px"} height={"32px"} />,
      },
    ],
    []
  );
  return <SideMenu SidebarData={SidebarData} profile={profile} />;
};

export default SideMenuContainer;
