import React, { useState, useEffect, useCallback } from "react";
import Loader from "components/Common/Loader";
import { AuthProvider } from "contexts/Auth";
import authApi from "apis/auth";
import Router from "routes/Router";
import { ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import theme from "lib/theme";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [verified, setVerified] = useState(false);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);

  // ログイン情報保持
  const authCheck = async () => {
    setLoading(true);
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (!isLoggedIn || isLoggedIn === "false") {
      setIsLoggedIn(false);
    } else {
      const [profile, err] = await authApi.getProfile();
      if (err) {
        setLoading(false);
        setIsLoggedIn(false);
        return;
      }
      setIsLoggedIn(true);
      setVerified(true);
      setProfile(profile);
    }
    setLoading(false);
  };

  const initialSetting = useCallback(async () => {
    await authCheck();
  }, []);

  useEffect(() => {
    initialSetting();
  }, [initialSetting]);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        {loading ? (
          <Loader />
        ) : (
          <AuthProvider
            isLoggedIn={isLoggedIn}
            verified={verified}
            profile={profile}
          >
            <Router />
          </AuthProvider>
        )}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
