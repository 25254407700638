import React from "react";
import styled from "@emotion/styled";
import RouterLink from "components/Common/RouterLink";
import Can from "components/Can";

const StSideContainer = styled.aside`
  position: sticky;
  z-index: 998;
  display: flex;
  flex-direction: column;
  top: 80px;
  width: 120px;
  height: calc(100vh - 80px);
  background-color: #8fc31e;
  color: #fff;
`;

const StSideUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StSideLi = styled.li`
  text-decoration: none;
  list-style: none;
`;

const StSideMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
  height: 78px;
  background-color: #8fc31e;
  color: #fff;

  :hover {
    background-color: #6d8f26;
  }
`;

const StSideMenuTitle = styled.span`
  margin-top: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
`;

const SideMenu = ({ SidebarData, profile }) => {
  return (
    <StSideContainer>
      <StSideUl>
        {SidebarData.map((item, index) => {
          return (
            <Can
              key={index}
              role={profile.role}
              perform={item.static}
              permit={() => (
                <StSideLi>
                  <RouterLink to={item.path}>
                    <StSideMenu>
                      {item.icon}
                      <StSideMenuTitle>{item.title}</StSideMenuTitle>
                    </StSideMenu>
                  </RouterLink>
                </StSideLi>
              )}
            />
          );
        })}
      </StSideUl>
    </StSideContainer>
  );
};

SideMenu.propTypes = {};

export default SideMenu;
