import React, { useState, useContext } from "react";
import Header from "./presenter";
import { useHistory } from "react-router-dom";
import AuthContext from "contexts/Auth";
import authApi from "apis/auth";
import { useSWRConfig } from "swr";

const HeaderContainer = () => {
  const { isLoggedIn = false, profile = {}, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { cache } = useSWRConfig();
  const isOpen = Boolean(anchorEl);

  // ログインユーザメニューを開く
  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // ログインユーザメニューを閉じる
  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  // ログアウト
  const onLogout = async () => {
    // ログインユーザメニューを閉じる
    onCloseMenu();
    // ログアウト API実行
    await authApi.logout();
    // context 情報更新
    logout();
    cache.clear();
  };

  // ロゴクリック
  const onClickLogo = () => {
    // ホーム画面に遷移
    history.push("/");
  };

  return (
    <Header
      isLoggedIn={isLoggedIn}
      profile={profile}
      anchorEl={anchorEl}
      isOpen={isOpen}
      onLogout={onLogout}
      onClickLogo={onClickLogo}
      onOpenMenu={onOpenMenu}
      onCloseMenu={onCloseMenu}
    />
  );
};

export default HeaderContainer;
