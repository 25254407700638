import React, { createContext, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({
  isLoggedIn: isLoggedInProp,
  verified: verifiedProp,
  profile: profileProp,
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInProp);
  const [profile, setProfile] = useState(profileProp);
  const [verified, setVerified] = useState(verifiedProp);
  const [shouldVerifiy, setShouldVerifiy] = useState(false);

  // ログイン
  const login = async (profile) => {
    try {
      setProfile(profile);
      if (profile?.verify_flag && profile.verify_flag && !verified) {
        // 二段階認証必要
        setVerified(false);
        setShouldVerifiy(true);
        return;
      } else {
        // 二段階認証未設定は認証スキップ
        setVerified(true);
      }
      // localstorageにログイン情報格納
      localStorage.setItem("isLoggedIn", "true");
      // stateにログイン情報格納
      setIsLoggedIn(true);
    } catch (e) {
      console.log(e);
    }
  };

  // 二段階認証完了後ログイン状態にする
  const loginWithVerify = async (profile) => {
    if (profile) setProfile(profile);
    setVerified(true);
    setShouldVerifiy(false);
    // localstorageにログイン情報格納
    localStorage.setItem("isLoggedIn", "true");
    // stateにログイン情報格納
    setIsLoggedIn(true);
  };

  // ログアウト
  const logout = async () => {
    try {
      setProfile({});
      // localstorageのログイン情報削除
      localStorage.removeItem("isLoggedIn");
      // stateにログイン情報初期化
      setIsLoggedIn(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        profile,
        verified,
        shouldVerifiy,
        loginWithVerify,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
export { AuthProvider };
