import React from "react";
import styled from "@emotion/styled";
import { Menu, MenuItem as MUIMenuItem, Fade } from "@mui/material";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import DefaultProfileImage from "assets/default_user.png";

import Image from "../../Common/Image";

const Container = styled.header`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

const LoginUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const LoginUserInfo = styled(MUIMenuItem)``;

const LoginUserName = styled.div`
  margin-left: 8px;
  margin-right: 16px;
`;

const MenuItem = styled(MUIMenuItem)`
  min-width: 150px;
`;

const Header = ({
  isLoggedIn = false,
  profile = {},
  anchorEl,
  isOpen,
  onLogout,
  onClickLogo,
  onOpenMenu,
  onCloseMenu,
}) => {
  return (
    <Container>
      <LogoContainer onClick={onClickLogo}>
        <Logo />
      </LogoContainer>
      {isLoggedIn ? (
        <LoginUserContainer>
          <LoginUserInfo onClick={onOpenMenu}>
            <Image
              src={profile?.profile_url ?? DefaultProfileImage}
              alt={"profile image"}
              width={"40px"}
              height={"40px"}
              borderRadius={"50%"}
              loading={"lazy"}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = DefaultProfileImage;
              }}
            />
            <LoginUserName>{profile.name}</LoginUserName>
            <Arrow />
          </LoginUserInfo>
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onCloseMenu}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={onLogout}>ログアウト</MenuItem>
          </Menu>
        </LoginUserContainer>
      ) : null}
    </Container>
  );
};

export default Header;
