import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StImage = styled.img`
  position: ${(props) => props.position};
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
`;

const Image = ({
  src,
  alt,
  position,
  zIndex,
  top,
  right,
  bottom,
  left,
  width,
  height,
  border,
  borderRadius,
  loading = "lazy",
  onError,
}) => {
  return (
    <StImage
      src={src}
      alt={alt}
      position={position}
      zIndex={zIndex}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      width={width}
      height={height}
      border={border}
      borderRadius={borderRadius}
      loading={loading}
      onError={onError}
    />
  );
};

Image.propTypes = {
  loading: PropTypes.oneOf(["eager", "lazy"]).isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  position: PropTypes.string,
  zIndex: PropTypes.number,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
};

export default Image;
