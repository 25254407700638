import Btn from "components/Common/Btn";
import ContentContainer from "components/Container/ContentContainer";

const ErrorFallback = ({ error, resetErrorBoundary, children }) => {
  return (
    <ContentContainer
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
      borderRadius={"8px"}
      backgroundColor={"#fff"}
      boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.2)"}
      textAlign="center"
    >
      <div>
        <p>エラーが発生しました</p>
        <p>ページを更新してください</p>
        {/* <pre>{error.message}</pre> */}
        <Btn
          width={"208px"}
          height={"48px"}
          margin={"0 0 0 16px"}
          border={"none"}
          borderRadius={"4px"}
          fontSize={"16px"}
          fontWeight={"700"}
          lineHeight={"16px"}
          color={"#fff"}
          backgroundColor={"#8FC31F"}
          type={"button"}
          text={"更新する"}
          onClick={resetErrorBoundary}
        />
      </div>
      {children}
    </ContentContainer>
  );
};

export default ErrorFallback;
