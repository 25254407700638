import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const StLink = styled(Link)`
  margin: ${(props) => props.margin};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : "none"};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: ${(props) =>
      props.textDecoration ? props.textDecoration : "none"};
  }
`;

const RouterLink = ({
  margin,
  color,
  fontSize,
  fontWeight,
  textDecoration,
  to,
  children,
}) => (
  <StLink
    to={to}
    margin={margin}
    color={color}
    fontSize={fontSize}
    fontWeight={fontWeight}
    textDecoration={textDecoration}
  >
    {children}
  </StLink>
);

RouterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  margin: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  textDecoration: PropTypes.string,
};

export default RouterLink;
