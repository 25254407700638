import rules from "constants/Permission";

const check = (rules, role, action) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }
  return false;
};

const Can = (props) =>
  check(rules, props.role, props.perform) ? props.permit() : props.no();

Can.defaultProps = {
  permit: () => null,
  no: () => null,
};

export default Can;
