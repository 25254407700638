import { RoleType } from "constants/Role";
import { matchPath } from "react-router-dom";

export const RedirectRules = [
  { route: { path: "/", exact: true } },
  { route: { path: "/login", exact: true } },
  { route: { path: "/verify", exact: true } },
  { route: { path: "/members", exact: true } },
  { route: { path: "/users", exact: true } },
  { route: { path: "/medicals" }, only: [RoleType.admin, RoleType.search] },
  {
    route: { path: "/message" },
    only: [RoleType.admin, RoleType.medicalAdmin, RoleType.medicalOperator],
  },
  {
    route: { path: "/notifications" },
    only: [RoleType.admin, RoleType.medicalAdmin, RoleType.medicalOperator],
  },
  {
    route: { path: "/activation" },
    only: [RoleType.admin, RoleType.medicalAdmin],
  },
];

export const useRedirect = (role) => {
  if (!role) return {};
  const pathname = window.location.pathname;
  const rule = RedirectRules.find((rule) => matchPath(pathname, rule.route));
  const shouldRedirect = rule?.only && rule.only.indexOf(role) < 0;
  if (shouldRedirect) {
    return { redirect: "/404" };
  }
  return {};
};
